import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

interface LoginComponentProps {
}

interface LoginComponentProps {
  handleUser?: ((e: any) => Promise<void>) | undefined
}

const LoginComponent: React.FC<LoginComponentProps> = ({ handleUser }: LoginComponentProps) => {

  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <Row>
        <Col md={12} className="text-center">
          <h2>Please <a href="#" onClick={handleUser}>Login</a> to see this content</h2>
        </Col>
      </Row>
    </Container>
  )
}

export default LoginComponent
