import './App.css'
import React, { useEffect, useState } from 'react'

import '@near-wallet-selector/modal-ui/styles.css'
import Home from './components/Home'
import QuizList from './components/QuizList'
import QuizItem from './components/QuizItem'
import HeaderNavbar from './components/HeaderNavbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import axios from 'axios'
import Footer from './components/Footer'
import { EConfig } from './constants/EConfig'
import { map } from 'rxjs'
import { IUserBalance } from './interfaces/IUserBalance'
import NotificationBar from './components/NotificationBar'
import Redeem from './components/Redeem'
import { Navbar } from 'react-bootstrap'
import { Simulate } from 'react-dom/test-utils'
import eruda from 'eruda'

// eruda.init()

function App ({ currentUser, wallet }: { currentUser: any, wallet: any }): any {
  const [user, setUser] = useState(currentUser)
  const [lncUser, setLncUser] = useState(null)
  const [userBalance, setUserBalance] = useState<IUserBalance>({
    nLearnsBalance: 0,
    hotLearnsBalance: 0
  })

  useEffect(() => {
    wallet?.walletSelector?.store?.observable
      .pipe(
        map((state: any) => state?.accounts),
      )
      .subscribe((nextAccounts) => {
        if (nextAccounts.length > 0 && !currentUser) {
          window.location.reload()
        }
      });

    (async () => {
      try {
        if (currentUser) {
          let userData = localStorage.getItem('lncUserData') ?
            JSON.parse(localStorage.getItem('lncUserData') as string)
            : await loginLncUser(currentUser)
          if (userData?.user.user_email !== `${currentUser}@learnnear.club`) {
            throw new Error('incorrect user')
          }
          setUser(
            currentUser,
          )
          setLncUser(userData)
          const balance = await getUserBalance(currentUser) || {
            nLearnsBalance: 0,
            hotLearnsBalance: 0
          }
          setUserBalance(balance)
        }
      } catch (e) {
        localStorage.removeItem('lncUserData')
        window.location.reload()
        console.log(e)
      }
    })()
  }, [])

  const getUserBalance = async (accountId) => {
    try {
      const data = JSON.stringify({
        user_name: accountId
      })

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${EConfig.API_BASE_URL}wp-json/api/getLncBalance`,
        headers: {
          'Authorization': 'Bearer 12312dasdasdsadasd',
          'Content-Type': 'application/json'
        },
        data: data
      }

      const response = await axios(config)
      if (response?.data?.error == 1) {
        throw new Error(response.data.message)
      }
      const nLearnsBalance = parseFloat(response?.data?.nLearns_balance) || 0
      const hotLearnsBalance = parseFloat(response?.data?.hotLearns_balance) || 0
      return {
        nLearnsBalance,
        hotLearnsBalance
      }
    } catch (error) {
      localStorage.removeItem('lncUserData')
      window.location.reload()
      console.error(error)
    }
  }

  const loginLncUser = async (accountName: string) => {
    try {
      const data = JSON.stringify({
        'user_email': `${accountName}@learnnear.club`
      })

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${EConfig.API_BASE_URL}wp-json/api/login_user/`,
        headers: {
          'Authorization': 'Bearer 12312dasdasdsadasd',
          'Content-Type': 'application/json'
        },
        data: data
      }

      const response = await axios.request(config)
      const userData = response.data
      localStorage.setItem('lncUserData', JSON.stringify(userData.data))
      return userData.data
    } catch (error) {
      localStorage.removeItem('lncUserData')
      console.error('Error logging in:', error)
    }
  }

  const addAnyEvent = async (event, method = 'add_any_event') => {
    if (wallet.isSignedIn && wallet.accountId) {
      await wallet.callMethod({
        contractId: 'login.learnclub.near',
        method: method,
        args: { any_event: event },
      })
    }
  }

  const checkDrops = async (): Promise<number> => {
    if (wallet.isSignedIn && wallet.accountId) {
      const drops = await wallet.viewMethod({
        contractId: EConfig.REDEEM_CONTRACT,
        method: 'get_caller_drop',
        args: { account_id: wallet.accountId }
      })
      return wallet.yoctoToNear(drops)
    }
    return 0
  }

  const claim = async () => {
    await wallet.callMethod({
      contractId: EConfig.REDEEM_CONTRACT,
      method: 'claim',
      args: { site_owner: EConfig.REDEEM_CONTRACT },
    })
  }

  const handleUser = async (e: any) => {
    e.preventDefault()
    if (!user) {
      wallet.signIn()
    } else {
      await wallet.signOut()
      localStorage.clear()
      window.location.reload()
    }
  }
  // const isSupportable = () => {
  //   if (!user) {return true}
  //   return user.includes('.near') || user.includes('.tg')
  // }
  // if (!isSupportable()) {
  //   return (
  //     <>
  //       <Navbar bg="light" expand="lg">
  //         <Navbar.Brand href="/" style={{ marginLeft: '8px' }}>
  //           <img src="/logo-main-mobile.svg" alt="Logo"/>
  //         </Navbar.Brand>
  //       </Navbar>
  //       <div className="text-center">
  //         <h1>
  //           We are not supporting <br/> this type of wallet, <br/> please sign out and login with .tg, .near
  //         </h1>
  //         <button onClick={handleUser} style={{ backgroundColor: 'orange', color: 'white' }}>Sign Out</button>
  //
  //       </div>
  //       <Footer/>
  //     </>
  //   )
  // }
  return (
    <BrowserRouter>
      <NotificationBar/>
      <HeaderNavbar user={user} handleUser={handleUser} lncUser={lncUser} userBalance={userBalance}/>
      <Routes>
        <Route path="/"
               element={<QuizItem lncUser={lncUser} handleUser={handleUser} addAnyEvent={addAnyEvent}/>}/>
        <Route path="/redeem"
               element={<Redeem lncUser={lncUser} userBalance={userBalance} claim={claim} checkDrops={checkDrops}/>}/>
        {/*<Route path='/' element={<Home/>}/>*/}
        {/*<Route path='/quiz-list' element={<QuizList/>}/>*/}
        {/*<Route path='/quiz/:id' element={<QuizItem lncUser={lncUser} handleUser={handleUser} addAnyEvent={addAnyEvent}/>}/>*/}
      </Routes>
      <Footer/>
    </BrowserRouter>
  )

}

export default App
