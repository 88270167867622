export const EConfig = {
  REDEEM_CONTRACT: 'hotredeem.learnclub.near',

  API_BASE_URL: 'https://learnnear.club/',
  DEFAULT_QUIZ_ID: '66427',


  //LOCAL
  // API_BASE_URL: 'http://near-learn.web/',
  // DEFAULT_QUIZ_ID: '59463'
}