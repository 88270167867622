import React from 'react'
import ReactDOM from 'react-dom/client';

import './index.css'
import App from './App'

import { Wallet } from './Wallet'

async function init () {
  const wallet = new Wallet()

  await wallet.startUp()

  let currentUser

  const isSignedIn = wallet.isSignedIn
  if (isSignedIn) {
    currentUser = wallet.accountId
  }

  return { currentUser, wallet }
}

const initializeNear = async () => {
  try {
    const { currentUser, wallet } = await init()
    renderApp(currentUser, wallet)
  } catch (error) {
    console.error('Error initializing NEAR:', error)
  }
}

initializeNear().catch(console.log)

const renderApp = (currentUser: any, wallet: any) => {
  const rootElement = document.getElementById('root')!;
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
        <App currentUser={currentUser} wallet={wallet}/>
    </React.StrictMode>,
  )
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then((registration) => {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, (error) => {
      console.log('ServiceWorker registration failed: ', error);
    });
  });
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (let registration of registrations) {
      registration.unregister();
    }
  }).catch((error) => {
    console.log('Service worker unregister failed: ', error);
  });
}
