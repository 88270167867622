import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { getRedeemConfig } from '../utils/redeem'
import CountDownTimer from './CountDownTimer'
import axios from 'axios'
import { EConfig } from '../constants/EConfig'
import moment from 'moment-timezone';

const Redeem = ({ lncUser, userBalance, checkDrops, claim }) => {
  const [drops, setDrops] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [checked, setChecked] = useState(false)
  const [redeemWindow, setRedeemWindow] = useState<{ start: Date; end: Date, minHotLearns: number } | any>(null)
  const [isRedeemed, setIsRedeemed] = useState(false)
  const [startDateTimeShow, setStartDateTimeShow] = useState('')

  useEffect(() => {
    const fetchRedeemConfig = async () => {
      try {
        const response = await getRedeemConfig()
        const { start_redeem_window, end_redeem_window, min_hot_learns } = response.data

        const start = moment.tz(start_redeem_window, 'America/Toronto').toDate();
        const end = moment.tz(end_redeem_window, 'America/Toronto').toDate();

        setRedeemWindow({
          start: start,
          end: end,
          minHotLearns: min_hot_learns
        })
        const formattedStartDate = moment.tz(start_redeem_window, 'America/Toronto').format('MMMM D');
        const formattedStartTime = moment.tz(start_redeem_window, 'America/Toronto').format('hh:mm A');
        const redeemStartDate = `${formattedStartDate} at ${formattedStartTime} EDT!`;
        setStartDateTimeShow(redeemStartDate);

      } catch (error) {
        console.error('Failed to fetch redeem config', error)
        setError('Failed to load redeem configuration.')
      }
    }
    fetchRedeemConfig().catch(console.log)
  }, [])

  const resetState = () => {
    setDrops(0)
    setLoading(false)
    setError(null)
    setChecked(false)
  }

  const handleCheckDrops = async () => {
    setLoading(true)
    setError(null)
    setChecked(true)
    try {
      const dropsResult = await checkDrops()
      setDrops(dropsResult)
    } catch (err) {
      setError('Failed to check eligibility')
    } finally {
      setLoading(false)
    }
  }

  const handleClaimWithEmail = async () => {
      await handleClaim('testkurva@gmail.com')
  }

  const handleClaim = async (email: string) => {
    setLoading(true)
    setError(null)
    try {
      let token = ''
      token = lncUser?.token

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${EConfig.API_BASE_URL}wp-json/api/redeem/`,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: { email: email }
      }
      await claim()
      await axios(config)
      setIsRedeemed(true)
    } catch (err) {
      setError('Failed to claim')
    } finally {
      setLoading(false)
    }
  }

  const now = new Date()

  const isEnoughBalance = redeemWindow?.minHotLearns ? userBalance.hotLearnsBalance >= redeemWindow.minHotLearns : true

  const isRedeemWindowOpen = isEnoughBalance && redeemWindow && now >= redeemWindow.start && now <= redeemWindow.end
  const shouldDisplayStatus = redeemWindow !== null

  return (
    <Container className="text-center">
      <Row>
        <Col md={8} className="mx-auto">
          <h1>Redeem hotLEARNs</h1>
          {shouldDisplayStatus && (
            <>
              {isRedeemWindowOpen && isEnoughBalance ? (
                <>
                  {!isRedeemed && <CountDownTimer endDate={redeemWindow.end} onComplete={resetState}/>}
                </>
              ) : !isRedeemWindowOpen && !isEnoughBalance ? (
                <p>You should have at least {redeemWindow.minHotLearns} hotLEARNs to redeem</p>
              ) : (
                <p>
                  Redeem will open on {startDateTimeShow}<br/>
                  Support <a
                  href="https://t.me/Learn_NEAR_Club_Hot_Village">https://learnnear.club/hot/</a>
                </p>
              )}
            </>
          )}
          {lncUser ? (
            <>
              {!checked && isRedeemWindowOpen && (
                <Button onClick={handleCheckDrops} disabled={loading}>
                  {loading ? 'Checking...' : 'Check eligibility'}
                </Button>
              )}
              {checked && !loading && !drops && isRedeemWindowOpen && (
                <h1>Sorry, you have nothing to redeem.</h1>
              )}
              {checked && drops > 0 && isRedeemWindowOpen && (
                <div>
                  {!isRedeemed && (
                    <>
                      <pre style={{ fontSize: '14px' }}>Redeem {JSON.stringify(drops, null, 2)} Near</pre>
                      <Button style={{backgroundColor:"#00c08b", borderColor:"#00c08b"}} onClick={handleClaimWithEmail}>
                        {loading ? 'Claiming...' : 'Redeem'}
                      </Button>
                    </>
                  )}
                </div>
              )}
              {isRedeemed && (
                <p style={{ color: 'green' }}>You have successfully redeemed hotLEARNs!</p> // Display success message after redemption
              )}
              {error && <p style={{ color: 'red' }}>{error}</p>}
            </>
          ) : (
            <p>Please log in to redeem hotLEARNs.</p>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default Redeem
