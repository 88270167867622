import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { IUserBalance } from '../interfaces/IUserBalance'

interface NavbarProps {
  user: any,
  handleUser: any,
  userBalance?: IUserBalance
  lncUser?: any
}

const HeaderNavbar: React.FC<NavbarProps> = ({ user, handleUser, userBalance, lncUser }) => {
  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="/" style={{ marginLeft: '8px' }}>
        <img src="/logo-main-mobile.svg" alt="Logo"/>
      </Navbar.Brand>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={NavLink} to="/redeem">
            Redeem
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Nav className="ml-auto" style={{ marginLeft: 'auto', marginRight: '8px' }}>
        <Nav.Item>
          {!!user ? (
            <React.Fragment>
              <Nav.Link><img src="/user.png" width="25px" height="25px" alt="user"/>{user}</Nav.Link>
              <Nav.Link style={{ paddingTop: 0 }}>
                <img src="/trophy.png" width="15" height="15"
                     alt="thropy"/>{userBalance?.nLearnsBalance != null &&
                <span> {userBalance?.nLearnsBalance} nLEARNs</span>}
                {userBalance?.hotLearnsBalance != null &&
                  <span> {userBalance?.hotLearnsBalance} 🔥hotLEARNs</span>}
              </Nav.Link>
              <Nav className="mr-auto d-block d-md-none">
                <Nav.Link as={NavLink} to="/redeem">
                  Redeem
                </Nav.Link>
              </Nav>
            </React.Fragment>
          ) : (
            <NavLink to="/login" className="nav-link" onClick={handleUser}>Login</NavLink>
          )}
        </Nav.Item>
        {/*{!!user && (*/}
        {/*  <Nav.Item>*/}
        {/*    <NavLink to='/logout' className="nav-link" onClick={handleUser}>Sign Out</NavLink>*/}
        {/*  </Nav.Item>*/}
        {/*)}*/}
      </Nav>

      {/*  <Navbar.Toggle aria-controls="basic-navbar-nav"/>*/}
      {/*  <Navbar.Collapse id="responsive-navbar-nav">*/}
      {/*    <Nav className="mr-auto">*/}
      {/*      <Nav.Link as={NavLink} to='/quiz-list'>*/}
      {/*        LNC Quiz*/}
      {/*      </Nav.Link>*/}
      {/*    </Nav>*/}
      {/*    <Nav className="ml-auto" style={{marginLeft: 'auto', marginRight: '8px'}}>*/}
      {/*      <Nav.Item>*/}
      {/*        {!!user ? (*/}
      {/*          <Nav.Link>*/}
      {/*            {user} {userBalance != null && <span>{userBalance} nLearns</span>}*/}
      {/*          </Nav.Link>*/}
      {/*        ) : (*/}
      {/*          <NavLink to='/login' className="nav-link" onClick={handleUser}>Login</NavLink>*/}
      {/*        )}*/}
      {/*      </Nav.Item>*/}
      {/*      {!!user && (*/}
      {/*        <Nav.Item>*/}
      {/*          <NavLink to='/logout' className="nav-link" onClick={handleUser}>Sign Out</NavLink>*/}
      {/*        </Nav.Item>*/}
      {/*      )}*/}
      {/*    </Nav>*/}
      {/*  </Navbar.Collapse>*/}
    </Navbar>
  )
}

export default HeaderNavbar
