import axios from 'axios';
import { EConfig } from '../constants/EConfig';

export const getQuizList = async (callback) => {
  try {
    const response = await axios.get(`${EConfig.API_BASE_URL}wp-json/api/quiz-list`);
    callback(response.data.data.quiz_list);
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

export const getQuiz = async (id, callback) => {
  try {
    const response = await axios.get(`${EConfig.API_BASE_URL}wp-json/api/quiz-data/${id}`);
    callback(response.data.data.quiz);
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};


export const isQuizPassed = async (quizId: string, token: string, callback) => {
  let isPassed = false;
  try {
    const response = await axios.get(`${EConfig.API_BASE_URL}wp-json/api/is-quiz-passed/${quizId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (response?.data?.data?.isPassed === true) {
      isPassed = true;
    }
    callback(isPassed);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const getUserQuiz = async (token: string): Promise<any> => {
  try {
    const response = await axios.post(
      `${EConfig.API_BASE_URL}wp-json/api/get-user-quiz`,
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};