import React, { useEffect, useState } from 'react'
import Countdown, { CountdownRenderProps } from 'react-countdown'

interface CountdownRendererProps {
  cooldown: number;
  onComplete?: () => void;
}

const QuizCountDownTimer: React.FC<CountdownRendererProps> = ({ cooldown, onComplete }) => {
  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    if (completed && onComplete) {
      onComplete()
    }
  }, [completed, onComplete])

  const endDate = new Date(Date.now() + cooldown * 1000)

  const renderer = ({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
    setCompleted(completed)

    if (completed) {
      return <span className="countdown-closed">Loading new quiz...</span>
    } else {
      return (
        <>
          <h1 className="text-center">Please wait before accessing new quiz</h1>
          <div className="countdown">
            {/*<div className="countdown-item">*/}
            {/*  <span className="countdown-value">{days}</span>*/}
            {/*  <span className="countdown-label">d</span>*/}
            {/*</div>*/}
            {/*<div className="countdown-item">*/}
            {/*  <span className="countdown-value">{hours}</span>*/}
            {/*  <span className="countdown-label">h</span>*/}
            {/*</div>*/}
            <div className="countdown-item">
              <span className="countdown-value">{minutes}</span>
              <span className="countdown-label">m</span>
            </div>
            <div className="countdown-item">
              <span className="countdown-value">{seconds}</span>
              <span className="countdown-label">s</span>
            </div>
          </div>
        </>
      )
    }
  }

  return <Countdown date={endDate} renderer={renderer}/>
}

export default QuizCountDownTimer
