import { setupWalletSelector } from '@near-wallet-selector/core'
import { setupHereWallet } from '@near-wallet-selector/here-wallet'
import { setupModal } from '@near-wallet-selector/modal-ui'
import nearApi, { providers } from 'near-api-js'

const THIRTY_TGAS = '30000000000000'
const NO_DEPOSIT = '0'

export class Wallet {
  walletSelector
  wallet
  network
  createAccessKeyFor
  isSignedIn
  isLoaded
  accountId

  constructor () {
    this.createAccessKeyFor = 'login.learnclub.near'
    this.network = 'mainnet'
  }

  async startUp () {
    this.walletSelector = await setupWalletSelector({
      network: this.network,
      modules: [
        setupHereWallet(),
      ]
    })

    this.isSignedIn = this.walletSelector.isSignedIn()
    this.isLoaded = true

    if (this.isSignedIn) {
      this.wallet = await this.walletSelector.wallet()
      this.accountId = this.walletSelector.store.getState().accounts[0].accountId
    }

    return this.isSignedIn
  }

  // Sign-in method
  signIn () {
    const description = 'Please select a wallet to sign in.'
    const modal = setupModal(this.walletSelector, { contractId: this.createAccessKeyFor, description })
    modal.show()
  }

  // Sign-out method
  signOut () {
    this.wallet.signOut()
    this.wallet = this.accountId = this.createAccessKeyFor = null
    window.location.replace(window.location.origin + window.location.pathname)
  }

  async viewMethod ({ contractId, method, args = {} }) {
    const { network } = this.walletSelector.options
    const provider = new providers.JsonRpcProvider({ url: network.nodeUrl })

    let res = await provider.query({
      request_type: 'call_function',
      account_id: contractId,
      method_name: method,
      args_base64: Buffer.from(JSON.stringify(args)).toString('base64'),
      finality: 'optimistic',
    })
    return JSON.parse(Buffer.from(res['result']).toString())
  }
  async callMethod({ contractId, method, args = {}, gas = THIRTY_TGAS, deposit = NO_DEPOSIT, outcomeData = false }) {
    try {
      const outcome = await this.wallet.signAndSendTransaction({
        signerId: this.accountId,
        receiverId: contractId,
        actions: [
          {
            type: 'FunctionCall',
            params: {
              methodName: method,
              args,
              gas,
              deposit,
            },
          },
        ],
      });
      if (outcomeData) {
        return outcome;
      } else {
        const result = await providers.getTransactionLastResult(outcome);
        console.log('SOBAKA');
        console.log(result)
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getTransactionResult (txhash) {
    const { network } = this.walletSelector.options
    const provider = new providers.JsonRpcProvider({ url: network.nodeUrl })

    // Retrieve transaction result from the network
    const transaction = await provider.txStatus(txhash, 'unused')
    return providers.getTransactionLastResult(transaction)
  }

  async yoctoToNear (yocto: number): Promise<number> {
    return parseFloat(String(yocto / 1e24));
  }
}
