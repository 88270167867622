import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Alert, Button } from 'react-bootstrap'
import { EConfig } from '../constants/EConfig'

interface Announcement {
  message: string;
}

const NotificationBar: React.FC = () => {
  const [announcement, setAnnouncement] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [visible, setVisible] = useState<boolean>(true)

  useEffect(() => {
    const getAnnouncement = async () => {
      try {
        const response = await axios.get<Announcement>(`${EConfig.API_BASE_URL}wp-json/api/active-announcement`)
        setAnnouncement(response.data.message)
      } catch (err: any) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }

    getAnnouncement().catch(console.log)
  }, [])

  if (!visible || !announcement) return null
  if (loading) return null

  return (
    <Alert variant="warning" onClose={() => setVisible(false)} dismissible className="lnc-announcement">
      <div className="lnc-announcement-content">
        <div dangerouslySetInnerHTML={{ __html: announcement! }}/>
      </div>
    </Alert>
  )
}

export default NotificationBar
