import axios from 'axios'
import { EConfig } from '../constants/EConfig'

export const getRedeemConfig = async () => {
  try {
    const response = await axios.post(`${EConfig.API_BASE_URL}wp-json/api/redeem-window/`);
    return  response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};
